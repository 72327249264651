import React, { ReactElement, Fragment, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles';
import { breakpoints } from '../../styles/variables';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { useLocation } from '@reach/router';

export const GrabAttention = ( ): ReactElement => {
 
  const gradAttentionRef = useRef(null);
  const { isVisible } = useVisibilityDetection(gradAttentionRef);
  const location = useLocation(); 

  return (

    <Styled.SafariWrapper >
      <Styled.Wrapper ref={gradAttentionRef} isVisible={isVisible}>
        <Styled.Content>
          <Styled.Heading>
            <Styled.HeadingTitle>SketchWow Helps You Grab-Attention With Visually Engaging Graphics...</Styled.HeadingTitle>
            <Styled.Illustration
                src="/salespage/10th section/Attention-Span-47s.svg"
                alt="Illustration"
                loading="lazy"
              />
              <Styled.AttentionSpan > 
              <p>47 seconds. That's the average attention span these days. Can you believe it? It's only getting worse....</p>
              <p>Solution? Create designs with pizzaz and personality that really stand out.</p>
              <p>Using SketchWow to create unique, handdrawn style visuals is the perfect way to cut-through-the-clutter and boost engagement... so you can be better seen and heard.</p>
              <p>People from all over the world (in every language you can think of) love using SketchWow for personal projects, in the classroom and across dozens of industries, including...</p>
              </Styled.AttentionSpan>
          </Styled.Heading>          
       
        </Styled.Content>     

        <Styled.Section>
          
        <TwoColSection
          styles={css`
            max-width: 900px;
            margin-top: 30px;
            
            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 40px;
              padding:0;
            }
            
          `}

          leftColumn={
            <Styled.LeftColumn css={css`width: 300px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              width:100%;
              margin-top:30px;
            }            
            `}> 
                <Styled.SubTitle>Authors & Info Product Publishers</Styled.SubTitle>
                <Styled.MiniSub >
                  
                  <p>Did you know... There are 11,000 books published daily. 88% of those are non-fiction.</p>
                  <p>With steep competition like that... how do you stand out? Sprinkle some eye-catching visuals into your book or information product. Authors love 
                    SketchWow because they can create clear, casual designs that compliment their writing and summarize key points.</p>
                </Styled.MiniSub>
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`
            @media screen and (max-width: ${breakpoints.lg}px) {
              text-align:center;
            }
            `}>

                <Styled.Illustration
                    alt="Illustration"
                    src=  "/salespage/10th section/authors.png"
                    loading="lazy"
                    
                  />

            </Styled.RightColumn>
          }
        />
        <br/>

        <Styled.TextEnder >
        <p>Unless it's a Stephen King novel... nobody wants to read a book or ebook with zero visuals (or typical circle and square designs). Talk about snooze-ville.</p>
        <p>SketchWow comes pre-loaded with tons of gorgeous templates. This makes it real easy to find that perfect hand drawn visual to compliment whatever it is you are explaining or expressing in your book</p>
        </Styled.TextEnder>
        <br/>


      <TwoColSection
          styles={css`
            max-width: 900px;
            margin-top: 40px;

            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 40px;
              padding:0;
            }
        
          `}



          leftColumn={
            <Styled.LeftColumn css={css`margin-left: -100px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              margin-left: 0px;
              text-align:center;
            }
            
            `}> 
              <Styled.Illustration
                  alt="Illustration"
                  src= "/salespage/10th section/teachers.png"
                  loading="lazy"
                />
                
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`width: 300px; margin-left: 100px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              width:100%;
              margin-left:0;
              margin-bottom:30px;
            }
            `}>
                <Styled.SubTitle>Trainers & Teachers (Students too!)</Styled.SubTitle>
                <Styled.MiniSub >
                  <p>Delivering a lesson (in person or virtual) these days has its own set of challenges. Attention spans are shrinking in an overly-distracted
                    world. How do you get people talking and interested in what you have to say? Create conversation-worthy handouts, visuals, lessons and slides.</p>
                  <p>Forget grabbing a typical "boiler plate" template. Those old-school visuals often get ignored. Try something fun, fresh and different that commands attention.</p>
                </Styled.MiniSub>
              
            </Styled.RightColumn>
          }
        />
        <br/>
        <Styled.TextEnder >
        <p>Teachers and educators across the world love how SketchWow helps to explain complex (sometimes dull) topics in a refreshing way. The casual nature of handdrawn designs 
          gets your audience to listen and pay attention... because you are presenting information in a unique and interesting way.
        </p>
        </Styled.TextEnder>
        <br/>
        


        <TwoColSection
          styles={css`
            max-width: 900px;
            margin-top: 30px;


            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 40px;
              padding:0;
            }

           
          `}



          leftColumn={
            <Styled.LeftColumn css={css`width: 300px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              width:100%;
              margin-top:30px;
            }
            `}> 
                <Styled.SubTitle>Information Technology</Styled.SubTitle>
                <Styled.MiniSub >
                  
                  <p>Even though Network Diagrams and UML Workflows are more on the serious side... why not make them fresh and unique?</p>
                  <p>A growing number of IT Professionals choose SketchWow to map out their ideas, concepts, process and technical diagrams - from structure to behavioral and everything in between</p>
                </Styled.MiniSub>
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`
            @media screen and (max-width: ${breakpoints.lg}px) {
              text-align:center;
            }
            `}>

                <Styled.Illustration
                    alt="Illustration"
                    src= "/salespage/10th section/IT.png"
                    loading="lazy"                    
                  />

            </Styled.RightColumn>
          }
        />
        <br/>

        <Styled.TextEnder >
        <p>Choose from a variety of shapes and icons to explain your process, workflow or use case. SketchWow makes plain-Jane diagrams come alive.</p>
        </Styled.TextEnder>
        <br/>


      <TwoColSection
          styles={css`
            max-width: 900px;
            margin-top: 40px;


            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 40px;
              padding:0;
            }

           
          `}



          leftColumn={
            <Styled.LeftColumn css={css`margin-left: -100px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              text-align:center;margin-left:0;
            }
            
            `}
            > 
              <Styled.Illustration
                  alt="Illustration"
                  src= "/salespage/10th section/digital-marketers.png"
                  loading="lazy"
                />
                
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`width: 300px; margin-left: 100px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              width:100%;
              margin-left:0;
              margin-bottom:30px;
            }
            `}>
                <Styled.SubTitle>Digital Marketers</Styled.SubTitle>
                <Styled.MiniSub >
                  <p>Ever heard the term "Purple Cow" coined by Seth Godin? It’s about making your marketing so remarkable it stands out and gets attention.</p>
                  <p>What does the exact opposite? Using the same ole brochure template, cut-and-paste proposal cover pages and "stock photo" visuals as everyone else.</p>
                </Styled.MiniSub>
              
            </Styled.RightColumn>
          }
        />
        <br/>
        <Styled.TextEnder >
        <p>To really stand out, make your brand POP and express the unique products and services that you offer, try incorporating SketchWow sketches into your marketing process - from 
          advertising, internal communication to prospecting and client retention. SketchWow is like the Swiss Army Knife of marketing design awesomeness. 
        </p>
        </Styled.TextEnder>
        <br/>
  


        <TwoColSection
          styles={css`
            max-width: 900px;
            margin-top: 30px;


            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 40px;
              padding:0;
            }

           
          `}



          leftColumn={
            <Styled.LeftColumn css={css`width: 300px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              width:100%;
              margin-top:30px;
            }
            `}> 
                <Styled.SubTitle>Agencies & Consultants</Styled.SubTitle>
                <Styled.MiniSub >
                  
                  <p>Communicating complex processes, customer journeys or proposed services is not always a fun topic. It's easy to watch your clients eyes glaze over
                    as they review your 25-part flowchart in Courier font. It's like serving dry toast with no butter.
                  </p>
                  <p>How about dazzling your audience with catchy visuals that are not only fun to view... but clearly get your key points and message across. Plus, Gets people talking.</p>
                </Styled.MiniSub>
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`
            @media screen and (max-width: ${breakpoints.lg}px) {
              text-align:center;
            }
            `}>

                <Styled.Illustration
                    alt="Illustration"
                    src="/salespage/10th section/agencies.png"
                    loading="lazy"                    
                  />
            </Styled.RightColumn>
          }
        />
        <br/>

        <Styled.TextEnder >
        <p>Next time you want to explain, present or communicate an idea... rather than reach for that 2009 trusty template seen by millions before you... let SketchWow be your secret-sauce for stunning sketches!</p>
        </Styled.TextEnder>
        <br/>


      <TwoColSection
          styles={css`
            max-width: 900px;
            margin-top: 40px;


            @media screen and (max-width: ${breakpoints.md}px) {
              margin-top: 40px;
              padding:0;
            }
           
          `}


          leftColumn={
            <Styled.LeftColumn css={css`margin-left: -100px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              text-align:center;margin-left:0;
            }
            
            `}> 
              <Styled.Illustration
                  alt="Illustration"
                  src= "/salespage/10th section/businesses.png"
                  loading="lazy"
                />
                
            </Styled.LeftColumn>
          }

          rightColumn={
            <Styled.RightColumn css={css`width: 300px; margin-left: 100px;
            @media screen and (max-width: ${breakpoints.lg}px) {
              width:100%;
              margin-left:0;
              margin-bottom:30px;
            }            
            `}>
                <Styled.SubTitle>Businesses (Startups To Enterprise)</Styled.SubTitle>
                <Styled.MiniSub >
                  <p>Whether it's internal or external... all businesses are driven by communicating ideas, processes, plans, changes and procedures. Not to mention... constantly
                    pitching, promoting and positioning to gain market share. So many things to do.
                  </p>
                </Styled.MiniSub>
              
            </Styled.RightColumn>
          }
        />
        <br/>
        <Styled.TextEnder >
        <p>There are dozens of tools out there that help you communicate your ideas and concepts. Often, the exercise of creating using these tools is daunting enough... leaving 
          you uninspired and unmotivated to complete the task or do your best work. That was yesterday.
        </p>
        <p>Our customers tell us that SketchWow provides a very different user experience. They actually look forward to whipping-up that next diagram or design using SketchWow.</p>
        <p>Can you imagine that?</p>
         <p> From artists to small town pool companies... non-profits to big banks... they use SketchWow (true fact!)</p>
          <p>...and yes... Designers use SketchWow (can you believe it?). Even though SketchWow is built for non-designers, savvy pros appreciate how easy it is to knock-out
            catchy designs - and charge clients big-bucks too!
          </p>
        </Styled.TextEnder>
        <br/>

        </Styled.Section>

      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
